import { gsap } from "../gsap/all.js";
import { ScrollTrigger } from "../gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);
gsap.config({nullTargetWarn:false});
export default {

  init() {
    let topShape = document.querySelector('.top-shape');
    let midShape = document.querySelector('.mid-shape');
    let pstl = gsap.timeline({
      scrollTrigger: {
        trigger: '.single-body',
        start:"top center",
      },
    });
    pstl.to(topShape, { x: 0, rotation: 0, duration: 1 })

    let mstl = gsap.timeline({
      scrollTrigger: {
        trigger: '.single-body',
        start:"top top",
      },
    });
    mstl.to(midShape, { x: 0, rotation: 0, duration: 1 })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
