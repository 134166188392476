import { gsap } from "../gsap/all.js";
import { ScrollTrigger } from "../gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);
gsap.config({nullTargetWarn:false});
export default {

  init() {
    setTimeout(() => {
      let sections = gsap.utils.toArray('.tl-panel');

      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".timeline",
          pin: true,
          start: 'top 120px',
          scrub: 1,
          end: "+=3500",
        },
      });

      let sectionsmob = gsap.utils.toArray('.tl-panel-mob');

      gsap.to(sectionsmob, {
        xPercent: -100 * (sectionsmob.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".timeline-mobile",
          pin: true,
          start: '110% 98%',
          scrub: 1,
          end: "+=3500",
        },
      });
    }, 200)

    // Callout
    // Refacoring without ScrollTrigger Pinning - Jenny/Matt change decision 9/15
    // let currentIndex = 0
    // const panels = Array.from(document.querySelectorAll('.frame'))
    // if (panels.length) {
    //   ScrollTrigger.create({
    //     trigger: '.cs-cols',
    //     pin: true,
    //     pinSpacing: true,
    //     scrub: true,
    //     start: '110% 90%',
    //     end: function () {
    //       return '+=' + window.innerHeight * panels.length
    //     },
    //     toggleClass: 'is-active',
    //     onEnter: function () {
    //       panels[0].classList.add('is-active')
    //     },
    //     onLeaveBack: function () {
    //       panels[0].classList.remove('is-active')
    //     },
    //     onUpdate: function (self) {
    //       const previousIndex = currentIndex

    //       currentIndex = Math.floor(self.progress * panels.length)

    //       if (previousIndex >= panels.length || currentIndex >= panels.length)
    //         return

    //       if (previousIndex !== currentIndex) {
    //         panels[previousIndex].classList.remove('is-active')
    //         panels[currentIndex].classList.add('is-active')
    //       }
    //     },
    //   })
    // }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
