import { gsap } from "../gsap/all.js";
import { ScrollTrigger } from "../gsap/ScrollTrigger.js";
import { ScrollToPlugin } from "../gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
gsap.config({nullTargetWarn:false});
export default {

  init() {
    let sideMenu = document.querySelector('.side-menu');
    let pstl = gsap.timeline({
      scrollTrigger: {
        trigger: sideMenu,
        start: "top 240px",
        endTrigger: ".cta-wrap",
        end: "top bottom",
        pin: true,
        pinSpacing: false,
      },
    });

    let links = gsap.utils.toArray(".side-menu li a");
    links.forEach(a => {
      let element = document.querySelector(a.getAttribute("href")),
      linkST = ScrollTrigger.create({
        trigger: element,
        start: "top 240px",
      });
      ScrollTrigger.create({
        trigger: element,
        start: "top center",
        end: "bottom center",
        onToggle: self => self.isActive && setActive(a),
      });
      a.addEventListener("click", e => {
        console.log(e)
        e.preventDefault();
        gsap.to(window, {duration: 1, scrollTo: linkST.start, overwrite: "auto"});
      });
    });
    function setActive(link) {
      links.forEach(el => el.classList.remove("active"));
      link.classList.add("active");
    }

    let topShape = document.querySelector('.top-shape');
    let midShape = document.querySelector('.mid-shape');
    let sstl = gsap.timeline({
      scrollTrigger: {
        trigger: '.single-body',
        start:"top center",
      },
    });
    sstl.to(topShape, { x: 0, rotation: 0, duration: 1 })

    let tstl = gsap.timeline({
      scrollTrigger: {
        trigger: '.single-body',
        start:"top top",
      },
    });
    tstl.to(midShape, { x: 0, rotation: 0, duration: 1 })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
