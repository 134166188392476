// This file is used to set up the JS routing, do not add any JS code here other than to create a new route. For Global JS use common.js. 
// For template type specific JS you can import a file here using kebob case. EX: a post type archive page body class of 'post-type-archive-az_resources' would be 
// named and imported as postTypeArchiveAz_resources.js: import postTypeArchiveAzResources from './routes/postTypeArchiveAz_resources';


// source: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
(function() {
  if (typeof window.CustomEvent === 'function') return false

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
})()

// import external dependencies
import 'jquery';
import 'slick-carousel';

// Import everything from autoload
import './autoload/_foundation.js'; import './autoload/Modal.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import aboutUs from './routes/aboutUs';
import singleLlcaseStudies from './routes/singleLlcaseStudies';
import templateBrandassets from './routes/templateBrandassets'

/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  aboutUs,
  singleLlcaseStudies,
  templateBrandassets,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
